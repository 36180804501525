'use strict';

const tinyslider = require('tiny-slider');

$(document).ready(function () {
    $('[data-slider="true"]').each(function () {
        var mobileSlides = $(this).data('mobileslds');
        var tabletSlides = $(this).data('tabletslds');
        var desktopSlides = $(this).data('desktopslds');
        var gutters = $(this).data('gutters');
        const sliderID = new Date().getTime();
        $(this).find('.my-slider').attr('data-slider-container', sliderID);
        $(this).find('.my-slider-controls').attr('data-slider-controls', sliderID);
        tinyslider.tns({
            container: '[data-slider-container="' + sliderID + '"]',
            mode: 'carousel',
            axis: 'horizontal',
            nav: false,
            rewind: true,
            touch: true,
            gutter: gutters,
            controlsContainer: '[data-slider-controls="' + sliderID + '"]',
            preventScrollOnTouch: 'auto',
            swipeAngle: 30,
            responsive: {
                340: {
                    items: mobileSlides
                },
                768: {
                    items: tabletSlides
                },
                1024: {
                    items: desktopSlides
                }
            }
        });
        if ($(this).data('edge')) {
            $(this).children().find('div.tns-ovh').addClass('mr-5 mr-sm-0');
        }
    });

    $(".tns-visually-hidden").addClass('d-none');
});

$(document).ready(function () {
    $(window).on('load', function () {
        setTimeout(function () {
            function initializeEinsteinCarousel() {
                $('[data-einstein-slider="true"]').each(function () {
                    // Filter out script tags from the selection
                    var sliderContent = $(this).children().not('script');
                    var mobileSlides = $(this).data('mobileslds');
                    var tabletSlides = $(this).data('tabletslds');
                    var desktopSlides = $(this).data('desktopslds');
                    var gutters = $(this).data('gutters');
                    const sliderID = new Date().getTime();

                    // div for the scripts
                    var scriptContainer = $('<div>').addClass('script-container');
                    $('.experience-einstein-carousel .tns-liveregion.tns-visually-hidden').hide();

                    // Move the script tags to the scriptContainer
                    $(this).children('script').each(function () {
                        scriptContainer.append($(this));
                    });

                    $(this).attr('data-slider-container', sliderID);
                    $(this).parent().find('.my-slider-controls').attr('data-slider-controls', sliderID);

                    tinyslider.tns({
                        container: '[data-slider-container="' + sliderID + '"]',
                        mode: 'carousel',
                        axis: 'horizontal',
                        nav: false,
                        rewind: true,
                        touch: true,
                        gutter: gutters,
                        controlsContainer: '[data-slider-controls="' + sliderID + '"]',
                        preventScrollOnTouch: 'auto',
                        swipeAngle: 30,
                        responsive: {
                            340: {
                                items: mobileSlides
                            },
                            768: {
                                items: tabletSlides
                            },
                            1024: {
                                items: desktopSlides
                            }
                        }
                    });

                    // Append the sliderContent and scriptContainer as siblings
                    $('.einstein-carousel').append(scriptContainer);

                    // hide tns-liveregion (counter)
                    $(this).closest('.tns-outer').find('.tns-liveregion').hide()
                })
            }
            initializeEinsteinCarousel()
        }, 1000);
    })
})


